<template>
  <el-card header="隐私政策" class="box-card" shadow="never" style="min-height:750px">
    <div class="policy">
      <div class="content">
        <h2>隐私政策</h2>
        <p>工蚁宝平台（http://www.grejob.com/）依据本协议的规定提供服务，本协议具有合同效力。您已知悉且同意以下所有条款并完成个人资料的填写，才能保证享受到工蚁宝平台提供的网络服务。我平台承诺在服务过程中收集到的您个人信息，将严格遵循合法、正当、必要的原则，您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</p>
        <h3>用户信息的范围</h3>
        <p>工蚁宝向您收集的用户信息系能单独或者与其他信息相结合识别您的个人身份的信息，包括但不限于您的姓名、联系电话、身份证号、电子邮箱、住址、账户密码、贵公司名称、所在地等用户信息，上述用户信息是您获得我平台提供服务的基础，该等信息均是您自愿提供。</p>
        <h3>用户信息的收集</h3>
        <ol>
          <li>1、当您注册工蚁宝平台会员填写注册表时，注册表要求您填写用户名、用户密码、身份证号码、联系电话、电子邮箱等相关信息，该会员注册信息中的用户名称和用户密码需由您自行负责保密，您只能通过您的密码使用您的账号，如果您泄漏了密码，您可能会丢失您的个人信息，并且可能出现对您不利的后果。</li>
          <li>2、当您在工蚁宝平台给客服留言时，我们可能要求您填写留言信息，留言信息包含您个人的姓名、电话、微信号等相关信息。</li>
        </ol>
        <h3>用户信息的保护</h3>
        <ol>
          <li>1、工蚁宝平台将尽一切合理努力保护其获得的用户信息，遵守相关法律规定要求的标准，但工蚁宝平台仍然无法保证您的信息的绝对安全性。因此，用户应采取积极措施保证个人信息的安全，如：不将自己的帐号密码等用户信息透露给他人。因您的原因造成的账号、密码等信息被冒用、盗用或非法使用，由此引起的风险和损失需要由您自行承担。</li>
          <li>2、工蚁宝平台提供的用户信息保护措施仅适用于工蚁宝平台，工蚁宝平台可能保留有与第三方网站或网址的链接，访问这些链接将由用户自己作出决定，工蚁宝平台对这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的合法性、准确性、完整性、充分性和可靠性概不负责，工蚁宝平台提供这些第三方链接仅仅在于提供方便，并不表示工蚁宝平台对认可或推荐这些链接的任何内容和立场信息，也不是用于宣传或广告目的，一旦您离开工蚁宝平台，浏览或使用其他网站、服务及内容资源，工蚁宝平台即没有能力及义务保护您在工蚁宝平台以外的网站提交的任何用户信息，无论您登录或浏览上述网站是否基于工蚁宝平台的链接或引导。</li>
          <li>3、工蚁宝平台发现法律、行政法规禁止发布或者传输的信息的，将立即停止传输该信息，采取消除等处置措施，防止信息扩散，保存有关记录，并向有关主管部门报告。</li>
          <li>4、为了保障您的资金安全，请把手机及其他设备的密码设置成与账户的密码不一致。如您发现有他人冒用或盗用您的账户或其登录名及密码，或您的手机或其他有关设备丢失时，请您立即以有效方式通知我们；您还可以向我们申请暂停或停止相关服务。</li>
          <li>5、工蚁宝账户仅限您本人使用，请勿转让、借用、赠与、继承。</li>
        </ol>
        <h3>用户信息的披露</h3>
        <ul>
          <li>
            未经您本人允许，工蚁宝平台不会向任何第三方披露您的个人信息，下列情形除外：
            <ol>
              <li>1、工蚁宝平台已经取得您或您监护人的授权；</li>
              <li>2、司法机关或行政机关给予法定程序要求工蚁宝平台披露的；</li>
              <li>3、工蚁宝平台为维护自身合法权益而向用户提起诉讼或仲裁时；</li>
              <li>4、法律法规规定的其他情形。</li>
            </ol>
          </li>
        </ul>
        <h3>投诉和举报</h3>
        <p>工蚁宝平台在网站首页的底部设置有网络信息安全投诉、举报的链接，用户可通过客服QQ、投诉电话、不良信息举报中心网站链接、经营性网站备案信息网站链接投诉举报，工蚁宝平台受理后将及时依法处理。</p>
        <h3>用户信息保密协议修改</h3>
        <p>工蚁宝平台有权在必要时单方决定修改本协议，一旦本协议内容发生变动，将会在本网站的重要页面上提示发布修改之后的《个人信息保密协议》，该提示发布行为视为工蚁宝平台已经通知您修改行为。工蚁宝平台也可通过其他适当方式向用户提示修改内容。用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。</p>
        <p><strong>本保密协议的解释权归工蚁宝平台所有。</strong></p>
      </div>
    </div>
  </el-card>  
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.policy{
  ol,ul{
    list-style-type: none;
  }
  .content{
    font-size: 14px;
    padding: 0 30px;
    line-height: 1.5;
    h2{
      font-size: 16px;
      text-align: center;
      line-height: 2;
    }
    h3{
      font-size: 14px;
      line-height: 2;
    }
  }
}
</style>